<script>
    import { Line } from 'vue3-chart-v2';

    export default {
        name: 'LineChart',
        extends: Line,
        props: {
            chartData: {
                type: Object,
                required: true,
            },
            chartOptions: {
                type: Object,
                required: false,
            },
        },
        watch: {
            chartData: function (value) {
                this.renderChart(value, this.chartOptions);
            },
            chartOptions: function (value) {
                this.renderChart(this.chartData, value);
            },
        },
    };
</script>
